@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700&display=swap');

:root {
  --primary-color: #004494;
  --link-color: #2D72D6;
  --link-hover-color: #3665ab;
  --breadcrumb-active-color: #26C0E2;
  --button-primary-hover-background-color: #1E95B0;
  --button-secondary-color: #fff;
  --button-secondary-background-color: #26C0E2;
  --button-secondary-hover-background-color: #1E95B0;

  --main-font-family: 'Open Sans';
}

html {
  color: #292929;
}

body {
  font: 400 14px/19px var(--main-font-family), sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
}

h3 {
  font-weight: 500;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
}

a, .uk-link {
  color: var(--link-color);
}

a:hover, a:focus, a:active {
  color: var(--link-hover-color);
}

.clear-style {
  padding-left: 0px !important;
}

.uk-navbar-container:not(.uk-navbar-transparent) {
  box-shadow: 2px 15px 50px rgba(41,44,61,.1);
}

.uk-flex {
  display: flex !important;
}

.uk-flex-between {
  justify-content: space-between !important;
}

.uk-radio:checked, .uk-checkbox:checked, .uk-checkbox:indeterminate {
  background-color: var(--primary-color);
}

/* ========================================================================
   Header
 ========================================================================== */

#header_main {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}

/* ========================================================================
   Buttons
 ========================================================================== */

.uk-button {
  border-radius: 30px;
  text-transform: none;
}

.uk-button-primary {
  background-color: var(--primary-color);
  color: #fff;
  border: 1px solid #fff;
}

.uk-button-primary:hover {
  background-color: var(--button-primary-hover-background-color);
}

.uk-button-secondary {
  background-color: var(--button-secondary-background-color);
  color: var(--button-secondary-color);
}

.uk-button-secondary:hover {
  background-color: var(--button-secondary-hover-background-color);
}

.uk-button-default {
  background-color: #fff;
  border: 1px solid #B5B5B5;
  color: #B5B5B5;
}

.uk-button-action {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000038;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
}

.button-gray {
  background-color: #ededec;
  color: #000000FF;
  border: 1px solid transparent;
}

.button-gray:hover,
.button-gray:focus,
.button-gray:active,
.button-gray.uk-active {
  background-color: #bdbdbc;
  color: #000000FF;
}

/* ========================================================================
   Pagination
 ========================================================================== */

.resultsInfo {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  opacity: 1;
}

.resultsInfo .number {
  color: var(--primary-color);
}

ul.uk-pagination {
  margin: 10px 0 10px 0;
}

.uk-pagination > li > a, .uk-pagination > li > span {
  border: none;
  padding: 4px 8px;
  min-width: 32px;
  line-height: 24px;
  height: 32px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-shadow: none;
  color: #212121;
  border-radius: 4px;
}

.uk-pagination > li.uk-active > a, .uk-pagination > li.uk-active > span {
  background: var(--primary-color);
  color: #fff;
}

.uk-pagination > li > a, .uk-pagination > li > span {
  border-radius: 15px;
}

.uk-pagination > li {
  text-align: center;
}

.uk-pagination>li>a.pagination_arrow:hover {
  color: var(--primary-color);
  background: none;
}

/* ========================================================================
   Modals
 ========================================================================== */

#modal-consent .uk-modal-body {
  padding: 70px;
}

/* ========================================================================
   Form tabs
 ========================================================================== */

.form-tabs.uk-tab > * > a, .chapter-tabs.uk-tab > * > a {
  text-transform: none;
  font-weight: 300;
  font-size: 16px;
  /*font: 300 16px Roboto;*/
  letter-spacing: 0px;
  color: #212121 !important;
  opacity: 0.6;
  /*padding: 8px 15px;*/
  padding: 10px 15px;
}

.form-tabs.uk-tab > .uk-active > a, .chapter-tabs.uk-tab > .uk-active > a {
  color: #fff !important;
  /*font-weight: 600;*/
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 4px;
  font-weight: normal;
}

.form-tabs.uk-tab-left::before, .chapter-tabs.uk-tab-left::before {
  border-left: none;
}

.form-tabs.uk-tab-left > li {
  margin-bottom: 15px;
}

/*.uk-tab-left > * > a {*/
/*  text-decoration: none;*/
/*  padding: 10px 15px;*/
/*}*/

/*.uk-tab > .uk-active > a {*/
/*//background-color: rgba(255, 205, 0, 0.7);*/
/*  background-color: var(--eosc-observatory-primary-color);*/
/*//background-color: rgba(0, 68, 148, 0.9);*/
/*//color: var(--black) !important;*/
/*  color: var(--white) !important;*/
/*  border-color: transparent;*/
/*  font-weight: normal;*/
/*}*/

.tabsTitle {
  font-weight: 300;
  font-size: 20px;
  /*font: 300 20px Roboto;*/
  letter-spacing: 0px;
  color: #212121;
  opacity: 0.6;
}

.uk-tab > * > a .circle-number {
  width: 25px;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
  background: #F0F5F5 0% 0% no-repeat padding-box;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
}

.uk-tab > .uk-active > a .circle-number {
  opacity: 0.6;
}

.circle-number .fa.fa-check {
  color: #26C0E2;
}

.stepsCompleted {
  font-weight: 600;
  color: #212121;
  opacity: 0.4;
}

.percentageNumber {
  font-weight: 600;
  color: #212121;
  opacity: 0.4;
  margin-top: 3px;
}

.requiredFields {
  font-weight: 300;
  color: #212121;
  opacity: 0.6;
  margin-top: 3px;
  padding: 8px 15px;
}

/* ========================================================================
   Form
 ========================================================================== */

.uk-width-9-10 {
  width: calc(100% * 9 / 10.001) !important;
}

.formContainer {
  /*background: #FFFFFF 0% 0% no-repeat padding-box;*/
  /*box-shadow: 0px 3px 6px #0000001A;*/
  /*border-radius: 4px;*/
  /*opacity: 1;*/
  padding: 20px;
}

.uk-form-label {
  font-weight: bold;
  font-size: 18px !important;
  /*font: 700 18px Roboto;*/
  letter-spacing: 0px;
  color: #212121;
  /*text-transform: capitalize;*/
  opacity: 0.81;
}

.uk-form-description {
  font-size: 14px;
  letter-spacing: 0px;
  color: #212121;
  opacity: 0.81;
}

.uk-input {
  height: 56px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  opacity: 1;
}

.uk-select {
  height: 56px !important;
}

.uk-textarea {
  height: 100px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  opacity: 1;
}

a.add-new-element {
  text-decoration-line: none;
  font-weight: bold;
  letter-spacing: -0.35px;
  color: #444444;
  opacity: 1;
}

a.add-new-element:hover {
  text-decoration: none;
}

a.remove-element {
  color: #B5B5B5;
  font-size: 25px;
  text-decoration-line: none;
}

.formGroupElement {
  padding: 40px;
  background: #F0F5F5 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
}

/*.ng-select {*/
/*  display: inline-block !important;*/
/*  vertical-align: middle;*/
/*  height: 56px;*/
/*  background: #FAFAFA 0% 0% no-repeat padding-box;*/
/*  border: 1px solid #D1D1D1;*/
/*  border-radius: 4px;*/
/*  opacity: 1;*/
/*}*/

/*.ng-select .ng-select-container {*/
/*  height: 56px;*/
/*}*/

/*.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {*/
/*  height: 56px;*/
/*}*/

/*.ng-select .ng-select-container .ng-value-container .ng-input > input {*/
/*  height: 56px;*/
/*  font-size: 14px;*/
/*  padding-left: 10px;*/
/*}*/

/*.ng-select .ng-clear-wrapper {*/
/*  margin-top: 17px;*/
/*}*/

/*.ng-dropdown-panel {*/
/*  background: #fff;*/
/*  box-shadow: 0 2px 5px rgba(0,0,0,.25);*/
/*  box-sizing: border-box;*/
/*}*/

/*.ng-dropdown-panel .ng-dropdown-panel-items {*/
/*  padding: 15px;*/
/*}*/

/*.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {*/
/*  padding-top: 10px;*/
/*}*/

/*.ng-select-container .ng-value-container .ng-value {*/
/*  height: 56px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  padding-left: 10px;*/
/*}*/

.uk-radio-label, .uk-checkbox-label {
  font-weight: normal;
  font-size: 16px;
}

.uk-suggestion {
  font-size: 12px;
  font-style: italic;
}

.dp-picker-input {
  background-color: #fff;
  border: 1px solid var(--default-element-bg);
  border-radius: 4px;
  height: 34px;
  padding: 0px 10px;
}

.ck-editor__editable_inline {
  min-height: 150px;
}

/*!* ========================================================================*/
/*   Dashboard sidebar*/
/* ========================================================================== *!*/

/*#sidebar_main {*/
/*  width: 320px;*/
/*  border-right: 1px solid rgba(0,0,0,.12);*/
/*  position: fixed;*/
/*  height: 100%;*/
/*  !*top: 0;*!*/
/*  !*fixme this is for EOSC - we want top: 80 for general*!*/
/*  top: 159px;  !* this is for EOSC *!*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  -webkit-transform: translate3d(-320px,0,0);*/
/*  transform: translate3d(-320px,0,0);*/
/*  z-index: 800;*/
/*  background: #F0F5F5;*/
/*  -webkit-transition: all 280ms cubic-bezier(.4,0,.2,1);*/
/*  transition: all 280ms cubic-bezier(.4,0,.2,1);*/
/*}*/

/*#sidebar_main, #sidebar_main *, #sidebar_main ::after, #sidebar_main ::before, #sidebar_main::after, #sidebar_main::before {*/
/*  -webkit-box-sizing: border-box;*/
/*  box-sizing: border-box;*/
/*}*/

/*.dashboard #sidebar_main {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/

/*.sidebar_main_active #sidebar_main {*/
/*  -webkit-transform: translate3d(0,0,0);*/
/*  transform: translate3d(0,0,0);*/
/*}*/

/*.dashboard #sidebar_main {*/
/*  overflow: auto;*/
/*  box-shadow: 1px 0px 6px #0000004D;*/
/*}*/

/*.dashboard #sidebar_main .menu_section {*/
/*  overflow: visible;*/
/*  margin-bottom: 15px;*/
/*  padding-bottom: 20px;*/
/*  flex: 0;*/
/*}*/

/*.dashboard #sidebar_main .menu_section .sidebar_heading {*/
/*  font-weight: 500;*/
/*  font-size: 14px;*/
/*  letter-spacing: 0px;*/
/*  color: #4D4D4D;*/
/*  text-transform: uppercase;*/
/*  opacity: 1;*/
/*  padding: 8px 25px;*/
/*  !*margin-top: 20px;*!*/
/*}*/

/*#sidebar_main .menu_section ul {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  list-style: none;*/
/*}*/

/*#sidebar_main .menu_section ul > li {*/
/*  padding: 0;*/
/*  margin: 0;*/
/*  list-style: none;*/
/*}*/

/*#sidebar_main .menu_section > ul > li.current_section {*/
/*  background-color: var(--primary-color);*/
/*}*/

/*#sidebar_main .menu_section > ul > li > a {*/
/*  font-weight: 500;*/
/*  font-size: 14px;*/
/*  color: #212121;*/
/*  padding: 8px 25px;*/
/*  display: block;*/
/*  overflow: hidden;*/
/*  position: relative;*/
/*}*/

/*#sidebar_main .menu_section > ul > li.current_section a {*/
/*  color: #fff !important;*/
/*  font-weight: bold !important;*/
/*}*/

/*.dashboard #sidebar_main .menu_section > ul > li:not([class*="md-bg-"]) > a:hover {*/
/*  !*background-color: var(--primary-color);*!*/
/*  !*opacity: 0.4;*!*/
/*  !*color: #fff;*!*/
/*  text-decoration: none;*/
/*}*/

/*.dashboard #sidebar_main .menu_section > ul li > a {*/
/*  font-weight: 400;*/
/*  letter-spacing: 0;*/
/*  color: #333432CC;*/
/*  opacity: 1;*/
/*}*/

/*#sidebar_main .menu_section > ul > li > a .menu_title {*/
/*  display: inline-block;*/
/*}*/

/*.sidebar_main_active #page_content {*/
/*  margin-left: 320px;*/
/*}*/

/* ========================================================================
   Breadcrumbs
 ========================================================================== */

/*.uk-breadcrumb {*/
/*  margin-bottom: 0px;*/
/*}*/

/*.uk-breadcrumb li a {*/
/*  font-size: 12px;*/
/*  !*font-weight: bold;*!*/
/*  !*color: #2B4371;*!*/
/*  color: #666;*/
/*  letter-spacing: 0;*/
/*}*/

/*.uk-breadcrumb li span {*/
/*  font-size: 12px;*/
/*  font-weight: bold;*/
/*  !*color: #4D586A;*!*/
/*  color: var(--breadcrumb-active-color) !important;*/
/*  letter-spacing: 0;*/
/*}*/

/*.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {*/
/*  content: ">";*/
/*  display: inline-block;*/
/*  margin: 0 10px;*/
/*  color: #2B4371;*/
/*}*/

/*.goBackLink {*/
/*  font-size: 15px;*/
/*  color: #0047BA;*/
/*  letter-spacing: 0.36px;*/
/*  font-weight: 700;*/
/*}*/

/* ========================================================================
   Component: Service provider & Service form
 ========================================================================== */

.titleAndActionsBar {
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  padding: 13px 20px;
  opacity: 1;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.pageTitle {
  opacity: 0.75;
}

.providerName {
  opacity: 1;
  font-size: 16px;
}

/* ========================================================================
   Component: My Service providers
 ========================================================================== */

.uk-card.provider-card {
  box-shadow: 0px 3px 6px #00000038;
  border-radius: 4px;
  color: #1A1A1A;
}

.uk-card-media-top {
  min-height: 115px;
}

.uk-card-media-top.approved {
  background-color: #def0e0;
}

.uk-card-media-top.pending {
  background-color: #FFEDC9;
}

.uk-card-media-top.rejected {
  background-color: #F9D2CC;
}

.uk-card-media-top.incomplete {
  background-color: #DBDBDB;
}

.uk-card-badge {
  position: absolute;
  top: 0px;
  left: 0px;
  right: unset;
  z-index: 1;
}

.uk-card-badge.uk-label {
  text-transform: none;
  padding: 9px 18px;
  border-radius: 4px 0px;
}

.approved .uk-label {
  background: #3C9947 0% 0% no-repeat padding-box;
}

.pending .uk-label {
  background: #f5c400 0% 0% no-repeat padding-box;
}

.pending-EPOT .uk-label {
  background: #f57200 0% 0% no-repeat padding-box;
}

.rejected .uk-label {
  background: #C41C02 0% 0% no-repeat padding-box;
}

.incomplete .uk-label {
  background: #4D4D4D 0% 0% no-repeat padding-box;
}

.provider-card .providerName {
  font-weight: 600;
  font-size: 16px;
}

.provider-card .statusLabel {
  color: #808080;
}

.provider-logo {
  width: 130px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  margin-top: -50px;
  margin-left: auto;
  text-align: center;
  margin-right: auto;
  height: 70px;
}

.provider-logo-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.provider-logo img {
  max-width: 90%;
  max-height: 90%;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}

.cardActionButtons {
  display: flex;
  justify-content: center;
}

.serviceStatuses .status-label {
  color: #000000;
  opacity: 0.6;
}

.serviceStatuses .status-value {
  font-weight: 600;
  color: #000000;
}

/* ========================================================================
   Component: Pending Services
 ========================================================================== */

.services-provider-container {
  background: #F0F5F5 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  padding: 25px 40px;
}

.services-provider-container > div {
  align-items: center;
}

.services-provider-container h3 {
  color: #333432;
  font-weight: bold;
}

.services-provider-container .status-label {
  color: #808080;
}

.uk-card.service-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  opacity: 1;
}

.service-card .action-buttons {
  border-left: 1px solid #ccc;
  /*padding: 10px 0px 10px 20px;*/
  padding-left: 20px;
  height: 100%;
}

.service-card .providerName {
  word-wrap:break-word;
}

.service-card .action-buttons button {
  width: 100%;
}

.service-card h4 {
  color: #333432;
  font-weight: 500;
}

/* ========================================================================
   Component: Service Providers List
 ========================================================================== */

.filters .uk-select {
  height: 38px !important;
}

.filters .uk-button-link {
  color: #000;
}

.filters .uk-button-link:hover {
  text-decoration: none;
}

.provider-card .action-buttons {
  border-left: 1px solid #ccc;
  /*padding: 10px 0px 10px 20px;*/
  padding-left: 20px;
  height: 100%;
}

.provider-card .providerName {
  word-wrap:break-word;
}

.provider-card .action-buttons button {
  width: 100%;
}

.provider-card .uk-button-link {
  color: #000;
  border-radius: 0px;
}

.provider-card .uk-button-link:hover {
  text-decoration: none;
}

/*.service-card h4 {*/
  /*color: #333432;*/
  /*font-weight: 500;*/
/*}*/



/*TODO fix from here to end*/


.project-bg-color {
  background-color: #214c9c !important;
}





/*!********************!*/
/*!*     Timeline     *!*/
/*!********************!*/
/*.al_timeline {*/
/*  position: relative;*/
/*  padding: 24px 20px 32px;*/
/*  font-size: 80%;*/
/*}*/

/*.al_timeline::after {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  bottom: 0;*/
/*  margin-left: -2px;*/
/*  left: 50%;*/
/*  content: '';*/
/*  width: 4px;*/
/*  !*background: rgba(255, 255, 255, 0.5);*!*/
/*  background: #D1303A;*/
/*  display: block;*/
/*}*/

/*.al_timeline_block::before, .al_timeline_block::after {*/
/*  content: " ";*/
/*  display: table;*/
/*}*/

/*.al_timeline_block::after {*/
/*  clear: both;*/
/*}*/

/*.al_timeline_block + * {*/
/*  margin-top: 10px;*/
/*}*/

/*.al_timeline_block:nth-child(2n) .al_timeline_content {*/
/*  float: right;*/
/*}*/

/*.al_timeline_block:nth-child(2n+1) .al_timeline_content {*/
/*  text-align: right;*/
/*}*/

/*.al_timeline_image {*/
/*  position: absolute;*/
/*  left: 50%;*/
/*  width: 40px;*/
/*  height: 40px;*/
/*  margin-left: -20px;*/
/*  background: #fff;*/
/*  border-radius: 50%;*/
/*  text-align: center;*/
/*  z-index: 10;*/
/*  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);*/
/*  margin-top: 12px;*/
/*}*/

/*.al_timeline_block.update .al_timeline_image {*/
/*  position: absolute;*/
/*  left: 50%;*/
/*  width: 24px;*/
/*  height: 24px;*/
/*  margin-left: -12px;*/
/*  background: #D1303A;*/
/*  border-radius: 50%;*/
/*  text-align: center;*/
/*  z-index: 10;*/
/*  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);*/
/*  margin-top: 20px;*/
/*}*/

/*.al_timeline_image > i {*/
/*  font-size: 32px;*/
/*  line-height: 64px;*/
/*}*/

/*.al_timeline_content {*/
/*  box-sizing: border-box;*/
/*  background: #fff;*/
/*  width: 44%;*/
/*  padding: 8px 15px;*/
/*  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);*/
/*  position: relative;*/
/*  border-radius: 4px;*/
/*}*/

/*.al_timeline_content::after {*/
/*  position: absolute;*/
/*  top: 24px;*/
/*  right: -20px;*/
/*  content: '';*/
/*  display: block;*/
/*  border: 10px solid transparent;*/
/*  !*border-left-color: transparent;*!*/
/*  border-left-color: #fff;*/
/*}*/

/*.al_timeline_block.update .al_timeline_content::after {*/
/*  position: absolute;*/
/*  top: 24px;*/
/*  right: -20px;*/
/*  content: '';*/
/*  display: block;*/
/*  border: 10px solid transparent;*/
/*  !*border-left-color: transparent;*!*/
/*  border-left-color: #214c9c;*/
/*}*/

/*.al_timeline_block:nth-child(2n) .al_timeline_content::after {*/
/*  border-left-color: transparent;*/
/*  border-right-color: #fff;*/
/*  right: auto;*/
/*  left: -20px;*/
/*}*/

/*.update.al_timeline_block:nth-child(2n) .al_timeline_content::after {*/
/*  border-left-color: transparent;*/
/*  border-right-color: #214c9c;*/
/*  right: auto;*/
/*  left: -20px;*/
/*}*/

/*.al_timeline_content h3 {*/
/*  margin: 0 0 5px 0;*/
/*}*/

/*.al_timeline_content p {*/
/*  margin: 0 0 5px 0;*/
/*}*/

/*.al_timeline_block.update .al_timeline_content {*/
/*  background: #214c9c;*/
/*  box-shadow: none;*/
/*  color: #fff;*/
/*}*/

/*.al_timeline_block.update .al_timeline_content h3 {*/
/*  color: #fff;*/
/*}*/

/******************************************************/

/************* wk overlays *******************/

.serviceicon .wk-panel h3 {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.serviceicon * + .wk-margin {
  margin-top: 10px;
}
.serviceicon .wk-margin, .serviceicon .wk-panel-teaser, .serviceicon .wk-panel-title {
  margin-bottom: 10px;
}

.wk-overlay {
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  transform: translateZ(0px);
}
.wk-overlay, .wk-overlay-area-content, .wk-overlay-area:not(:empty)::before {
  display: inline-block;
  vertical-align: middle;
}
.wk-button-group, .wk-button-group .wk-button:active, .wk-button-group .wk-button:hover, .wk-overlay {
  position: relative;
}
.wk-dotnav > li > a, .wk-icon-button, .wk-list > li > a, .wk-nav ul > li > a, .wk-nav > li > a, .wk-overlay, .wk-slidenav, .wk-subnav > li > a {
  border: medium none;
}

.wk-overlay-area-content > *:last-child, .wk-overlay-panel.wk-flex > * > *:last-child, .wk-overlay-panel > *:last-child, .wk-overlay > *:first-child {
  margin-bottom: 0;
}

.wk-overlay-active *:not(.wk-active) > .wk-overlay-panel:not(.wk-ignore), .wk-overlay-hover:not(:hover):not(.wk-hover) .wk-overlay-panel:not(.wk-ignore) {
  opacity: 0;
}

.wk-overlay-blur, .wk-overlay-fade, .wk-overlay-grayscale, .wk-overlay-scale, .wk-overlay-spin, [class*="wk-overlay-slide"] {
  transition-duration: 0.3s;
  transition-property: opacity, transform, filter;
  transition-timing-function: ease-out;
}
.wk-overlay-image {
  padding: 0;
}
.wk-overlay-panel {
  bottom: 0;
  color: #fff;
  left: 0;
  /*padding: 20px;*/
  position: absolute;
  right: 0;
  top: 0;
}

a[class*="wk-"] {
  cursor: pointer;
  text-decoration: none;
}

a[class*="wk-"]:hover {
  text-decoration: none;
}
.wk-link-reset, .wk-link-reset a, .wk-link-reset a:focus, .wk-link-reset a:hover, .wk-link-reset:focus, .wk-link-reset:hover {
  color: inherit;
  text-decoration: none;
}


/* ========================
          Loaders
 ========================== */

.loading {
  position: relative;
  min-height: 200px;
}

.loading-medium {
  position: relative;
  min-height: 400px;
}

.loading-big {
  position: relative;
  min-height: 600px;
}

.loader-small {
  background: url("../images/loaders/loader-small.gif") no-repeat center;
  z-index: 110;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.loader-big {
  background: url("../images/loaders/loader-big.gif") no-repeat center;
  z-index: 110;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.whiteFilm {
  background: #ffffff;
  z-index: 105;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
}

.transparentFilm {
  background: transparent;
  z-index: 105;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
}
