/* You can add global styles to this file, and also import other style files */

/* ng-select theme */
@import "~@ng-select/ng-select/themes/default.theme.css";

/* UIKit */
@import "node_modules/uikit/src/scss/variables-theme";
@import "node_modules/uikit/src/scss/mixins-theme";

@import '../assets/css/survey-tool-mixin-overwrites';

/* UIKit */
@import "node_modules/uikit/src/scss/uikit-theme";

/* Custom SCSS/SASS Files */
@import '../catalogue-ui/assets/scss/service-catalogue-theme.css';

/* project specific scss */
@import '../assets/css/survey-tool';
@import '../assets/css/sidebar.css';
@import '../assets/css/nav-sidebar.css';

.sidebar_main_active #page_content {
  //background-color: #f5f5f5;
  background-color: var(--medium-grey);
}

.ng-dropdown-panel {
  width: auto !important;
  min-width: 100%;
}
