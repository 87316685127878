/* ========================================================================
   Sidebar main
 ========================================================================== */

#sidebar_main {
  /*overflow: auto;*/
  position: fixed;
  top: var(--header-height) !important;
  box-shadow: 0 0 16px 2px #00000029;
  background: #fff;
  /*width: 280px;*/
  width: var(--sidebar-width);
  transform: translate3d(var(--sidebar-width-invert),0,0);
  -webkit-transform: translate3d(var(--sidebar-width-invert),0,0);
  -webkit-transition: all 280ms cubic-bezier(.4,0,.2,1);
  transition: all 280ms cubic-bezier(.4,0,.2,1);
  height: 100%;
  z-index: 800;
}

.sidebar_main_active #sidebar_main {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/* ========================================================================
   Menu section
 ========================================================================== */

#sidebar_main .menu_section {
  overflow: visible;
  margin-bottom: 15px;
  padding-bottom: 20px;
  flex: 0;
  padding-top: 35px;
}

.sidebar_main_active #sidebar_main .menu_section {
  overflow: auto;
  flex: 1;
  margin-bottom: 15px;
}

.dashboard #sidebar_main .menu_section .sidebar_heading {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0px;
  color: #4D4D4D;
  text-transform: uppercase;
  opacity: 1;
  padding: 8px 25px;
  /*margin-top: 20px;*/
}

#sidebar_main .menu_section.sidebar-footer {
  border-bottom: none;
  margin-bottom: 100px;
  width: 100%;
  position: absolute !important;
  bottom: 0;
}

#sidebar_main .menu_section.border_top {
  border-top: 1px solid #DEDEDE;
}

#sidebar_main .menu_section ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#sidebar_main .menu_section ul > li {
  padding: 0;
  margin: 0;
  list-style: none;
}

#sidebar_main .menu_section > ul > li > a {
  font-size: 16px;
}

#sidebar_main .menu_section > ul > li > a:hover {
  /*background-color: #ececec;*/
  border-radius: 6px;
}

#sidebar_main .menu_section > ul > li.current_section {
  background-color: transparent;
}

#sidebar_main .menu_section > ul > li.current_section a {
  /*background-color: rgba(255, 205, 0, 0.5);*/
  /*background-color: #ececec;*/
  border-radius: 6px;
}

#sidebar_main .menu_section > ul > li.current_section > a:hover {
  /*background-color: #ececec;*/
  /*background-color: rgba(255, 205, 0, 0.5);*/
}

#sidebar_main .menu_section > ul > li.current_section a {
  color: #1A1A1A !important;
  font-weight: bold !important;
}

#sidebar_main .menu_section .material-icons {
  padding: 3px;
  background-color: transparent;
  border-radius: 6px;
  color: var(--grey-700);
}

#sidebar_main .menu_section .current_section .material-icons {
  padding: 3px;
  /*background: transparent linear-gradient(254deg, var(--openaire-light-blue) 0%, var(--catalogue-light-purple) 100%) 0 0 no-repeat padding-box;*/
  background-color: var(--eosc-observatory-secondary-color);
  border-radius: 6px;
  color: var(--white);
}

/*.material-icons-small {*/
/*width: 12px !important;*/
/*height: 12px !important;*/
/*!*vertical-align: middle;*!*/
/*color: #6b6b6b;*/
/*}*/

#sidebar_main .menu_section > ul > li > a {
  font-weight: 400;
  font-size: var(--sidebar-font-size);
  color: #212121;
  padding: 8px 25px;
  display: block;
  overflow: hidden;
  position: relative;
}

/* ========================================================================
   Sidebar toggle
 ========================================================================== */

#sidebar_main #sidebar_toggle {
  position: fixed;
  z-index: 9800;
  top: 50%;
  left: 280px;
  left: var(--sidebar-width);
  width: 35px;
  height: 35px;
  transition: left 0.25s linear;
  transition: left var(--dashboard-transition-delay) linear;
  -webkit-transition: left 0.25s linear;
  -webkit-transition: left var(--dashboard-transition-delay) linear;
  -moz-transition: left 0.25s linear;
  -moz-transition: left var(--dashboard-transition-delay) linear;
  -o-transition: left 0.25s linear;
  -o-transition: left var(--dashboard-transition-delay) linear;
  transform: translate(-50%, -50%);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 500px;
  border: 1px solid #EAEAEA;
  background: #FFFFFF;
  color: #2C2C2C;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#sidebar_main #sidebar_toggle.closed {
  left: var(--toggle-sidebar-closed);
}

#sidebar_main #sidebar_toggle::before {
  content: '\e5cb';
  font-family: 'Material Icons';
  font-size: 25px;
  transition: transform 0.25s linear;
  transition: transform var(--dashboard-transition-delay) linear;
  -webkit-transition: transform 0.25s linear;
  -webkit-transition: transform var(--dashboard-transition-delay) linear;
  -moz-transition: transform 0.25s linear;
  -moz-transition: transform var(--dashboard-transition-delay) linear;
  -o-transition: transform 0.25s linear;
  -o-transition: transform var(--dashboard-transition-delay) linear;
}

#sidebar_main #sidebar_toggle.closed::before {
  transform: rotate(180deg);
}

#sidebar_main #sidebar_toggle:hover {
  background-color: var(--eosc-observatory-secondary-color) !important;
  color: var(--white);
  /*border-color: @dashboard-sidebar-toggle-border-hover;*/
}

/* ========================================================================
   Page content
 ========================================================================== */

.sidebar_main_active #page_content {
  margin-left: var(--sidebar-width);
}
