/* ========================================================================
   Component: Nav
 ========================================================================== */
/*
 * Reset
 */
.uk-nav,
.uk-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
/*
* 1. Center content vertically, e.g. an icon
* 2. Imitate white space gap when using flexbox
* 3. Reset link
* 4. Space is allocated solely based on content dimensions: 0 0 auto
 */
.uk-nav li > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  -moz-column-gap: 0.25em;
  column-gap: 0.25em;
  /* 3*/
  text-decoration: none;
}
/* 4 */
.uk-nav li > a > * {
  flex: none;
}
/*
 * Items
 * Must target `a` elements to exclude other elements (e.g. lists)
 */
.uk-nav > li > a {
  padding: 5px 30px;
}
/* Sublists
 ========================================================================== */
/*
 * Level 2
 * `ul` needed for higher specificity to override padding
 */
ul.uk-nav-sub {
  padding: 5px 0 5px 45px;
}
/*
 * Level 3 and deeper
 */
.uk-nav-sub ul {
  padding-left: 15px;
}
/*
 * Items
 */
.uk-nav-sub a {
  padding: 2px 0;
}
/* Parent icon modifier
 ========================================================================== */
.uk-nav-parent-icon > .uk-parent > a::after {
  content: "";
  width: 24px;
  height: 24px;
  margin-left: auto;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%232C2C2C%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: none !important;
  font-family: "Material Icons";
  font-weight: 400 !important;
  font-size: 1.5em;
  width: auto;
  height: auto;
  content: "\e5c5";
}

.uk-nav-parent-icon > .no-icon.uk-parent > a::after {
  content: '';
}

.uk-nav-parent-icon > .uk-parent.uk-open > a::after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%232C2C2C%22%20stroke-width%3D%221.1%22%20points%3D%221%204%207%2010%2013%204%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/* Inverse */
.sidebar_main_swipe {
  /* No sidebar */
  /* Mini Sidebar*/
}
.sidebar_main_swipe #sidebar_main #sidebar_content {
  width: var(--sidebar-width);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  /*padding-top: 65px;*/
  /*padding-top: var(--header-height);*/
  z-index: 60;
  background: #FFFFFF;
  /*border-right: 1px solid #EAEAEA;*/
  transition: 0.5s;
  transition-property: width;
  /*overflow-y: auto;*/
}
.sidebar_main_swipe #sidebar_main .menu_section {
  margin-left: 10px;
  position: relative;
  font-size: 14px;
}
.sidebar_main_swipe #sidebar_main .menu_section > ul > li > a {
  position: relative;
  padding: 10px 20px;
  line-height: 30px;
}
.sidebar_main_swipe #sidebar_main .menu_section > ul > li > a:not(.uk-flex) {
  display: block;
}
.sidebar_main_swipe #sidebar_main .menu_section > ul > li > a .menu-icon {
  display: block;
  padding: 6px;
  border-radius: 6px;
  background-color: transparent;
}
.sidebar_main_swipe #sidebar_main .menu_section > ul > li.uk-parent > a {
  padding: 10px 40px 10px 20px;
}
.sidebar_main_swipe #sidebar_main .menu_section > ul > li.uk-parent > a:not(.uk-flex)::after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  line-height: 20px;
}
.sidebar_main_swipe #sidebar_main .menu_section > ul > li.uk-parent .uk-nav-sub {
  margin-right: 10px;
  padding-left: 57px;
  position: relative;
}
.sidebar_main_swipe #sidebar_main .menu_section > ul > li.uk-parent .uk-nav-sub a {
  border-radius: 4px;
  padding: 7px 15px;
  justify-content: left;
}
.sidebar_main_swipe #sidebar_main .menu_section > ul > li.uk-parent .uk-nav-sub > li.uk-active > a {
  background-color: #F3F3F3;
  color: var(--eosc-observatory-primary-color) !important;
  justify-content: left;
}
.sidebar_main_swipe #sidebar_main .menu_section > ul > li.uk-parent .uk-nav-sub:before {
  content: "";
  left: 32px;
  position: absolute;
  height: 95%;
  /*border-left: 2px solid rgba(33, 31, 126, 0.3);*/
  border-left: 2px solid var(--eosc-observatory-secondary-color);
  opacity: 0.3;
}
.sidebar_main_swipe #sidebar_main .menu_section > ul > li.uk-active > a .menu-icon {
  background-color: #211F7E;
  background-image: linear-gradient(51deg, #4687E6 0%, #1F2379 100%);
  color: #FFFFFF;
}
.sidebar_main_swipe #page_content,
.sidebar_main_swipe openaire-user > user > div,
.sidebar_main_swipe openaire-error > div,
.sidebar_main_swipe #page_content .message,
.sidebar_main_swipe bottom > div {
  /*padding-left: 280px;*/
  transition: 0.5s;
}
.sidebar_main_swipe #page_content {
  /* for firefox */
}
.sidebar_main_swipe #page_content.uk-blur-background,
.sidebar_main_swipe #page_content .uk-blur-background {
  background: rgba(249, 249, 249, 0.99);
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .sidebar_main_swipe #page_content.uk-blur-background,
  .sidebar_main_swipe #page_content .uk-blur-background {
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    background: rgba(249, 249, 249, 0.8);
  }
}
@-moz-document url-prefix() {
  .sidebar_main_swipe #page_content.uk-blur-background,
  .sidebar_main_swipe #page_content .uk-blur-background {
    background: rgba(249, 249, 249, 0.99);
  }
}
.sidebar_main_swipe #page_content .message {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding-left: calc(var(--sidebar-width) - 100px);
  padding-right: 20px;
  text-align: center;
}
.sidebar_main_swipe #page_content #page_content_header.uk-sticky,
.sidebar_main_swipe #page_content #page_content_actions.uk-sticky {
  z-index: 979;
}
.sidebar_main_swipe #page_content #page_content_header.uk-sticky.uk-active,
.sidebar_main_swipe #page_content #page_content_actions.uk-sticky.uk-active {
  border-bottom: 1px solid #EAEAEA;
}
.sidebar_main_swipe #page_content #page_content_header.uk-sticky.uk-active .uk-tab,
.sidebar_main_swipe #page_content #page_content_actions.uk-sticky.uk-active .uk-tab {
  margin-bottom: 0;
}
.sidebar_main_swipe #page_content #page_content_header.uk-sticky.uk-active *:last-child.uk-tab:before,
.sidebar_main_swipe #page_content #page_content_actions.uk-sticky.uk-active *:last-child.uk-tab:before {
  content: none;
}
.sidebar_main_swipe #page_content #page_content_header {
  overflow-x: hidden;
}
.sidebar_main_swipe #page_content #page_content_header .info {
  height: 45px;
  overflow: hidden;
}
.sidebar_main_swipe #page_content #page_content_header .info img {
  max-height: 45px;
  max-width: 180px;
}
.sidebar_main_swipe #page_content,
.sidebar_main_swipe openaire-user > user > div,
.sidebar_main_swipe openaire-error > div {
  min-height: calc(100vh - 65px);
  min-height: calc(100vh - var(--header-height));
  /*background-color: #F9F9F9;*/
  position: relative;
  z-index: 70;
}
.sidebar_main_swipe:not(.sidebar_main_active):not(.sidebar_mini) #page_content,
.sidebar_main_swipe:not(.sidebar_main_active):not(.sidebar_mini) openaire-user > user > div,
.sidebar_main_swipe:not(.sidebar_main_active):not(.sidebar_mini) openaire-error > div,
.sidebar_main_swipe:not(.sidebar_main_active):not(.sidebar_mini) #page_content .message,
.sidebar_main_swipe:not(.sidebar_main_active):not(.sidebar_mini) bottom > div {
  padding-left: 0;
}
.sidebar_main_swipe:not(.sidebar_main_active):not(.sidebar_mini) #sidebar_main #sidebar_content {
  display: none;
  width: 0;
}
.sidebar_main_swipe.sidebar_mini #sidebar_main #sidebar_content {
  width: 125px;
}
.sidebar_main_swipe.sidebar_mini #page_content,
.sidebar_main_swipe.sidebar_mini openaire-user > user > div,
.sidebar_main_swipe.sidebar_mini openaire-error > div,
.sidebar_main_swipe.sidebar_mini #page_content .message,
.sidebar_main_swipe.sidebar_mini bottom > div {
  padding-left: 125px;
}
@media (max-width: 480px) {
  .sidebar_main_swipe #sidebar_main #sidebar_content {
    width: 100px !important;
  }
  .sidebar_main_swipe #page_content,
  .sidebar_main_swipe openaire-user > user > div,
  .sidebar_main_swipe openaire-error > div,
  .sidebar_main_swipe #page_content .message,
  .sidebar_main_swipe bottom > div {
    padding-left: 100px !important;
  }
  .sidebar_main_swipe #page_content .message {
    padding-right: 0;
  }
}
