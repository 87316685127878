@mixin hook-navbar() {

  // new rule
  .uk-navbar-nav > li > a {
    text-transform: none;
    color: var(--black);
  }

  .uk-navbar-container:not(.uk-navbar-transparent) {
    background: #fff;
  }
}

@mixin hook-tab() {

  .uk-tab > * > a {
    text-transform: none;
    color: red;
  }
}

@mixin hook-tab-item() {


  //color: red;
  //  text-transform: none;
}

@mixin hook-button() {

  .uk-button-primary {
    background-color: #004494;
  }

  .uk-button-primary:hover {
    background-color: #1e95b0;
  }

}
